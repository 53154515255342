import styled, { css } from "styled-components"
import media from "../../styles/media"

export const LowGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -20%, 0);
  min-width: 2500px;
  z-index: 0;
  filter: blur(24px);
  height: auto;
`

export const HighGradient = styled.img`
  position: absolute;
  left: 50%;
  top: ${props => props.top || "0%"};
  transform: translate3d(-50%, -50%, 0);
  min-width: 2000px;
  z-index: 0;
  filter: blur(24px);
  height: auto;
  pointer-events: none;

  // write props to hide on md and mobile, md should hide both md and mobile
  ${({ hideOnMd }) =>
    hideOnMd &&
    css`
      ${media.md`
        display: none;
      `}
    `}
  ${({ hideOnSm }) =>
    hideOnSm &&
    css`
      ${media.sm`
        display: none;
      `}
    `}
`

import { graphql, useStaticQuery } from "gatsby"

export const useGradient = klips => {
  const { pmGradient, klipsGradient } = useStaticQuery(
    graphql`
      query PowerMetricsEOPCta {
        pmGradient: directusFiles(
          directus_id: { eq: "b143ae2d-fab4-4821-b1a9-c3766fcf9317" }
        ) {
          id
          cdn
          title
        }
        klipsGradient: directusFiles(
          directus_id: { eq: "d71a55df-a7dc-4fe9-9eea-cc2904158b50" }
        ) {
          id
          cdn
          title
        }
      }
    `
  )

  const gradient = klips ? klipsGradient : pmGradient
  return { gradient }
}
